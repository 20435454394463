<template>
  <b-container>
    <GroupForm
      :data="data"
      :update="true"
      :merchants="[...merchants, ...dataMerchants]"
    >
      <template #append-form>
        <b-button
          variant="primary"
          @click="update"
        >
          Atualizar grupo
        </b-button>
      </template>
    </GroupForm>
  </b-container>
</template>

<script>
import {
    BContainer, BButton,
} from 'bootstrap-vue';

import GroupForm from './Form/GroupForm.vue';

import EcommerceGroupService from '@/service/merchant/mercury/group';
import EcommerceUserService from '@/service/merchant/mercury/merchant';

export default {
    components: {
        BContainer, GroupForm, BButton,
    },

    data() {
        return {
            id: this.$route.params.id,
            data: {},
            merchants: [],
            dataMerchants: [],
        };
    },

    async mounted() {
        this.callLoading(true);
        const { data } = await EcommerceUserService.getAll(1);
        this.merchants = data.data;
        await this.getData();
        this.callLoading(false);
    },

    methods: {
        async update() {
            const response = await this.confirmAnAction('Deseja realmente criar esse grupo?');

            if (!response) {
                return;
            }

            const { status } = await EcommerceGroupService.updateGroup({
                id: this.data.id,
                name: this.data.name,
            });

            if (status !== 200) {
                this.modalError('Erro ao criar grupo');
                return;
            }

            this.modalSuccess('Grupo criado com sucesso');
            this.$router.push('/ecommerce');
        },

        async getData() {
            const { data } = await EcommerceGroupService.geGroupById(this.id);
            this.dataMerchants = data.merchant;
            this.data = {
                ...data,
                merchants: data.merchant.map(item => item.id),
            };
        },
    },
};
</script>
